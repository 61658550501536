<template>
  <el-dialog
    custom-class="company-info-dialog"
    :visible.sync="visible"
    top="10vh"
    width="400px"
    @close="handleClose"
    v-on="$listeners">
    <template slot="title">
      <div class="title">{{ title }}</div>
      <el-divider style="margin:6px 0; height:2px"></el-divider>
    </template>
    <div style="text-align: center;">
      <el-form label-position="top">
        <el-form-item label="Only .xlsx file is acceptable.">
          <el-upload
            :key="uploadKey"
            :action="fileUploadUrl"
            :http-request="handleHttpRequest"
            :show-file-list="false"
            :limit="1"
            :headers="headers"
            :accept="accept"
            >
            <el-button size="mini">Upload File</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "UploadJob",
  data() {
    return {
      visible: false,
      title: 'Upload Job',
      fileUploadUrl: '',
      accept: ".xlsx",
      uploadKey: 1,
    //accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",  
      headers: {
        token: null,
      },
    }
  },
  created() {
    this.fileUploadUrl = this.$apis.baseUrl + this.$apis.uploadJobExcel
    this.headers.token = this.$store.state.token
  },
  mounted () {
    this.visible = true
  },
  methods: {
    handleClose() {
      this.visible = false
      this.$emit('close')
    },
    handleSuccess() {
      this.$emit('success')
    },
    handleHttpRequest(option) {
      const url = option.action
      const data = new FormData()
      data.append(option.filename, option.file, option.file.name)
      this.$request.post({url, data}).then(res => {
        if(res.code == '1000') {
          this.$message.success(res.data.result ?? 'Upload Job Successfully')
          this.handleSuccess()
          this.handleClose()
        } else {
          this.$message.error('Upload Job failed')
          this.uploadKey++
        }
      }).catch(e => {
      //  this.$message.error('Upload Job failed')
        this.uploadKey++
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.el-divider--horizontal{
  margin:2px 0;
  height:2px
}
.el-divider{
  background-color: #dcdfe6bd;
}
/deep/.el-dialog__body{
  padding:20px !important;
}
.el-form-item{
  margin-bottom: 0;
}
.title {
  color: #1D65C1;
  font-weight: 600;
}
</style>