var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "justify-content": "space-between",
          },
        },
        [
          _c(
            "span",
            {
              staticStyle: {
                "line-height": "40px",
                "text-align": "center",
                color: "#4C565C",
                "font-weight": "bold",
              },
            },
            [
              _vm._v(
                "Edit Ebdn Mass Flow Metering System Seals Checklist Items "
              ),
              _vm.haveSaved
                ? _c("i", {
                    staticClass: "el-icon-check",
                    staticStyle: { color: "green", "font-weight": "bolder" },
                  })
                : _vm._e(),
            ]
          ),
          _c(
            "el-button",
            {
              staticStyle: {
                "min-width": "100px",
                height: "30px",
                "margin-top": "5px",
              },
              attrs: { round: "", type: "primary", size: "small" },
              on: { click: _vm.save },
            },
            [_vm._v("Save")]
          ),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.categoryOptions, function (category) {
          return _c(
            "el-tab-pane",
            {
              key: category.name,
              attrs: { label: category.label, name: category.name },
            },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "header" },
                  [
                    _c("span", { staticClass: "text-span" }, [_vm._v("No")]),
                    _c("span", { staticClass: "text-span" }, [_vm._v("Item")]),
                    _c("span", { staticClass: "text-span" }, [
                      _vm._v("Tag No."),
                    ]),
                    _c("span", { staticClass: "text-span" }, [
                      _vm._v("Seals No."),
                    ]),
                    _c("span", { staticClass: "text-span" }, [
                      _vm._v("Remarks"),
                    ]),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          "min-width": "80px",
                          height: "30px",
                          "margin-top": "5px",
                        },
                        attrs: { round: "", type: "outlined", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.addRow(category.name)
                          },
                        },
                      },
                      [_vm._v("+ Add Item")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mfm-items-container" },
                  _vm._l(_vm.drag[_vm.activeName], function (item, i) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "item",
                        attrs: { draggable: "true" },
                        on: {
                          dragstart: function ($event) {
                            return _vm.dragstart($event, i)
                          },
                          dragenter: function ($event) {
                            return _vm.dragenter($event, i)
                          },
                          dragend: _vm.dragend,
                          dragover: _vm.dragover,
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#30619E",
                              "font-size": "14px",
                              "font-weight": "400",
                              "line-height": "40px",
                              "text-align": "center",
                            },
                          },
                          [_vm._v(_vm._s(i + 1))]
                        ),
                        _c("el-input", {
                          staticClass: "table-input",
                          attrs: {
                            size: _vm.isMobileScreen ? "small" : "default",
                          },
                          model: {
                            value: item.itemName,
                            callback: function ($$v) {
                              _vm.$set(item, "itemName", $$v)
                            },
                            expression: "item.itemName",
                          },
                        }),
                        _c("el-input", {
                          staticClass: "table-input",
                          attrs: {
                            size: _vm.isMobileScreen ? "small" : "default",
                          },
                          model: {
                            value: item.itemTag,
                            callback: function ($$v) {
                              _vm.$set(item, "itemTag", $$v)
                            },
                            expression: "item.itemTag",
                          },
                        }),
                        _c("el-input", {
                          staticClass: "table-input",
                          attrs: {
                            size: _vm.isMobileScreen ? "small" : "default",
                          },
                          model: {
                            value: item.sealNo,
                            callback: function ($$v) {
                              _vm.$set(item, "sealNo", $$v)
                            },
                            expression: "item.sealNo",
                          },
                        }),
                        _c("el-input", {
                          staticClass: "table-input",
                          attrs: {
                            size: _vm.isMobileScreen ? "small" : "default",
                          },
                          model: {
                            value: item.remark,
                            callback: function ($$v) {
                              _vm.$set(item, "remark", $$v)
                            },
                            expression: "item.remark",
                          },
                        }),
                        _c("el-button", {
                          staticStyle: {
                            height: "30px",
                            width: "30px",
                            "margin-top": "5px",
                          },
                          attrs: {
                            circle: "",
                            type: "danger",
                            icon: "el-icon-close",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.removeRow(item, i, category.name)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }