<template>
    <div>
      <div style="display: flex;flex-direction: row;justify-content: space-between;  ">
        <span  style=" line-height: 40px;text-align: center; color: #4C565C; font-weight: bold;">Edit Ebdn Mass Flow Metering System Seals Checklist Items
          <i class="el-icon-check"  style="color: green;font-weight: bolder;" v-if="haveSaved"/>
        </span>
        <el-button round style="min-width: 100px;height: 30px;margin-top: 5px;" type="primary" size="small" @click="save" >Save</el-button>
      </div>
      <el-tabs v-model="activeName" type="border-card" >
        <el-tab-pane
          :key="category.name"
          v-for="(category) in categoryOptions"
          :label="category.label"
          :name="category.name"
        >
          <div >
            <div class="header">
              <span  class="text-span">No</span>
              <span class="text-span">Item</span>
              <span class="text-span">Tag No.</span>
              <span class="text-span">Seals No.</span>
              <span class="text-span">Remarks</span>
              <!-- <span></span> -->
              <el-button round style="min-width: 80px;height: 30px;margin-top: 5px;" type="outlined" size="small" @click="addRow(category.name)" >+ Add Item</el-button>
            </div>
            <div  class="mfm-items-container">
              <div class="item"  v-for="(item, i) in drag[activeName]" :key="item.id" draggable="true"
                @dragstart="dragstart($event, i)" @dragenter="dragenter($event, i)" @dragend="dragend" @dragover="dragover" >
                <span style="color:#30619E; font-size: 14px;font-weight: 400;line-height: 40px; text-align: center;">{{i+1}}</span>
                <el-input
                  :size="isMobileScreen ? 'small' : 'default'"
                  class="table-input"
                  v-model="item.itemName"
                />
                <el-input
                  :size="isMobileScreen ? 'small' : 'default'"
                  class="table-input"
                  v-model="item.itemTag"
                />
                <el-input
                  :size="isMobileScreen ? 'small' : 'default'"
                  class="table-input"
                  v-model="item.sealNo"
                />
                <el-input
                  :size="isMobileScreen ? 'small' : 'default'"
                  class="table-input"
                  v-model="item.remark"
                />
                <el-button circle type="danger" icon="el-icon-close"  size="mini"
                  style="height:30px; width:30px;margin-top:5px"
                  @click="removeRow(item, i,category.name)"
                ></el-button>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { getEbdnMfmItemByBargeId,postEbdnMfmItemBarge } from '@/services/modules/comm.js'
export default {
  name: "ebdnMfmItemBarge",
  components: {
  },
  props: {
    bargeShipId: {
      type: String,
      default: null
    },
    ship: {
      type: Object,
      default: {},
    },
  },
  data(){

    const rules = {
      reqRule: [{ required: true, message: 'This field is required', trigger: ['blur', 'change'] }],
      notReqRule: [{ required: false }]
    }
    const categoryOptions =[
      { name: 'one', label: 'Mass Flow Metering Instrumen',value:1},
      { name: 'two', label: 'Pipe Line System',value:2},
      { name: 'three', label: 'Operational Seals',value:3},
    ]
    const categoryMap ={
      'one':1,
      'two':2,
      'three':3,
    }

    return{
      rules,
      categoryMap,
      categoryOptions,
      activeName:'one',
      dragIndex:0,
      drag: {
        one:[],
        two:[],
        three:[],
      },
      haveSaved:false
    }

  },
  computed: {
    ...mapState({
      isMobileScreen: "isMobileScreen",
    }),
  },
  mounted(){
    if (this.bargeShipId) {
      this.getEbdnMfmItemByBargeId(this.bargeShipId).then(resp=> {
        this.handlePopulateList(resp);
      })
    }
  },
  methods: {
    getEbdnMfmItemByBargeId,
    postEbdnMfmItemBarge,
    handlePopulateList(resp,isSave){
      if (resp.code == 1000 && resp.data.length>0) {
        this.drag.one = resp.data.filter(item => item.itemCategory == 1)
        this.drag.two = resp.data.filter(item => item.itemCategory == 2)
        this.drag.three = resp.data.filter(item => item.itemCategory == 3)
        this.haveSaved = isSave
      }
   },

    dragstart(e, index) {
    e.stopPropagation()
    this.dragIndex = index
    setTimeout(() => {
      e.target.classList.add('moveing')
      },0)
    },
    dragenter(e, index) {
      e.preventDefault()
      if (this.dragIndex !== index) {
        const catName = this.activeName
        const source = this.drag[catName][this.dragIndex];
        this.drag[catName].splice(this.dragIndex, 1);
        this.drag[catName].splice(index, 0, source);
        this.dragIndex = index
      }

    },
    dragover(e) {
      e.preventDefault()
      e.dataTransfer.dropEffect = 'move'
    },
    dragend(e) {
      e.target.classList.remove('moveing')
    },
    save(){
      const payload = this.drag.one.concat(this.drag.two.concat(this.drag.three))
      payload.forEach(item => {
        delete item.id;  delete item.createTime; delete item.createdBy; delete item.updatedBy; delete item.updateTime;
        item.updatedAt= new Date().toISOString();
      });

      this.postEbdnMfmItemBarge(payload).then(resp=> {
        this.handlePopulateList(resp,true)
      })
    },
    removeRow(row,index,category){
      this.drag[category].splice(index, 1);
    },
    addRow(category){
      const baseItem = {
        itemCategory: this.categoryMap[category],
        itemName:'',
        itemTag:'',
        bargeShipId:this.bargeShipId,
        bargeImo:this.ship?.imo,
        sealNo:'',
        remark:'',
      }
      this.drag[category].push(baseItem)
    },

  }
}

</script>

<style lang="scss" scoped>
.item,.header{
  display: grid;
  column-gap: 10px;
  grid-template-columns: 5% 20% 10% 20% 25% 10%;
}
.item {
  cursor: move;
  margin: 10px;
  color: #fff;
  font-size: 18px;
}

.container {
  position: relative;
  padding: 0;
}

.moveing {
opacity: 0;
}
.mfm-items-container{
  max-height:400px ;
  overflow: scroll;
}
.header{
  margin-left: 10px;
  margin-right: 10px;
  .text-span {
  color: #4C565C;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  line-height: 35px;
}

}

</style>



